import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,3,7];

export const dictionary = {
		"/(main)": [15,[5],[6]],
		"/(main)/checkin/[studentID][x+7c][campID][x+7c][pickUpTime][x+7c][orderId][x+7c][customerId]": [~16,[5],[6]],
		"/(camp-manager)/cm/dashboard": [~8,[2]],
		"/(camp-manager)/cm/login": [9,[2]],
		"/(camp-manager)/cm/logout": [10,[2]],
		"/(camp-manager)/cm/oscar-viewer": [11,[2]],
		"/(main)/creatortube/uploader/[locationID][x+7c][campType][x+7c][startDate][x+7c][endDate]": [17,[5],[6]],
		"/(main)/notification": [18,[5],[6]],
		"/(enrollment)/products": [12,[3],[4]],
		"/(enrollment)/products/[handle]": [13,[3],[4]],
		"/(main)/qrcode": [19,[5],[6]],
		"/(main)/scanner": [20,[5],[6]],
		"/(enrollment)/summary": [14,[3],[4]],
		"/(main)/tickets/[orderId]-[customerId]": [~21,[5],[6]],
		"/(main)/tickets/[orderId]-[customerId]/register": [~22,[5],[6]],
		"/(main)/user/[customerId]": [~23,[5,7],[6]],
		"/(main)/user/[customerId]/register": [~25,[5,7],[6]],
		"/(main)/user/[customerId]/[studentId]": [24,[5,7],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';